@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap);
@font-face {
  font-family: "Bookerly";
  font-weight: 400;
  src: url(/fonts/BookerlyLCD_It.ttf);
}

@font-face {
  font-family: "Amazon Ember";
  font-weight: 300;
  src: url(/fonts/AmazonEmber_Lt.ttf);
}

@font-face {
  font-family: "Amazon Ember";
  font-weight: 400;
  src: url(/fonts/AmazonEmber_Rg.ttf);
}

@font-face {
  font-family: "Amazon Ember";
  font-weight: 700;
  src: url(/fonts/AmazonEmber_Bd.ttf);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  border: 0;
  overflow: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.one_container__28jCh {
  background-color: #171717;
  height: 100vh;
  width: 100vw;
  display: flex;
  overflow: hidden;
  --dot-shadow-invisible: 0px 0px 50px 20px rgba(21, 112, 254, 0);
  --dot-shadow: 0px 0px 50px 20px rgba(21, 112, 254, 0.75);
  font-family: "Open Sans", sans-serif;
  flex-direction: column;
}

.one_dotone__2ZsJ5 {
  position: absolute;
  width: 50vh;
  height: 50vh;
  box-shadow: var(--dot-shadow);
  -webkit-animation-name: one_dotanimation__3X3fP;
          animation-name: one_dotanimation__3X3fP;
  -webkit-animation-duration: 4s;
          animation-duration: 4s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  z-index: 10;
}

@-webkit-keyframes one_dotanimation__3X3fP {
  0% {
    transform: scale(1.5);
    box-shadow: var(--dot-shadow-invisible);
  }
  20% {
    transform: scale(0.9);
    box-shadow: var(--dot-shadow-invisible);
  }
  100% {
    transform: scale(1);
    box-shadow: var(--dot-shadow);
  }
}

@keyframes one_dotanimation__3X3fP {
  0% {
    transform: scale(1.5);
    box-shadow: var(--dot-shadow-invisible);
  }
  20% {
    transform: scale(0.9);
    box-shadow: var(--dot-shadow-invisible);
  }
  100% {
    transform: scale(1);
    box-shadow: var(--dot-shadow);
  }
}

.one_leftColumn__1TUYT {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% - 60vh);
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.one_rightColumn__Xdruk {
  flex: 0 0 60vh;
  height: 100%;
  display: flex;
  align-items: center;
}

.one_conversations__nTRYs {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  list-style: none;
  padding: 0;
  margin: 0;
  text-indent: 0;
  font-weight: 300;
  color: white;
  font-size: 24px;
  overflow: hidden;
  padding: 0 32px;
  padding-right: 80px;
  position: relative;
  max-width: 750px;
  height: 100%;
}

.one_conversations__nTRYs li {
  margin-top: 40px;
  -webkit-animation-name: one_fadein__FOCnV;
          animation-name: one_fadein__FOCnV;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  padding-right: 100px;
}

.one_conversations__nTRYs li:nth-child(2n) {
  color: #237cfe;
  text-align: right;
  margin-top: 12px;
  padding-left: 100px;
  padding-right: 0;
  font-weight: 400;
}

.one_header__37xHW {
  flex: 0 0 15%;
  display: flex;
  align-items: center;
  padding: 0 32px;
  width: 100%;
}

.one_header__37xHW .one_logo__32TAw {
  height: 64px;
  margin-top: 12px;
}

.one_footer__2WVK4 {
  flex: 0 0 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1f1f1f;
}

.one_middle__2zWpX {
  flex: 1 1 100%;
  overflow: hidden;
  display: flex;
}

@-webkit-keyframes one_fadein__FOCnV {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes one_fadein__FOCnV {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.one_openCommand__lhJoG {
  font-family: Bookerly;
  background-color: white;
  border-radius: 8px;
  padding: 12px 32px;
  font-size: 24px;
}

h1 {
  color: white;
  font-weight: 400;
  margin: 0;
  padding: 0;
  /* margin-left: 24px; */
  font-size: 46px;
  white-space: nowrap;
}
.one_body__3ySrw {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  margin-top: 15%;
}

.one_body__3ySrw:before {
  background: linear-gradient(
    to bottom,
    rgba(23, 23, 23, 1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 150px;
  width: 100%;
  pointer-events: none;
  z-index: 1;
}

.echodottop_echodot__ZLRMy {
  display: flex;
  border-radius: 100%;
  background-image: url(/images/alexa_top_500.png);
  background-size: 102%;
  background-position: center;
}


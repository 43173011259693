@font-face {
  font-family: "Bookerly";
  font-weight: 400;
  src: url(/fonts/BookerlyLCD_It.ttf);
}

@font-face {
  font-family: "Amazon Ember";
  font-weight: 300;
  src: url(/fonts/AmazonEmber_Lt.ttf);
}

@font-face {
  font-family: "Amazon Ember";
  font-weight: 400;
  src: url(/fonts/AmazonEmber_Rg.ttf);
}

@font-face {
  font-family: "Amazon Ember";
  font-weight: 700;
  src: url(/fonts/AmazonEmber_Bd.ttf);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  border: 0;
  overflow: hidden;
}

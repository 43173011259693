@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap");

.container {
  background-color: #171717;
  height: 100vh;
  width: 100vw;
  display: flex;
  overflow: hidden;
  --dot-shadow-invisible: 0px 0px 50px 20px rgba(21, 112, 254, 0);
  --dot-shadow: 0px 0px 50px 20px rgba(21, 112, 254, 0.75);
  font-family: "Open Sans", sans-serif;
  flex-direction: column;
}

.dotone {
  position: absolute;
  width: 50vh;
  height: 50vh;
  box-shadow: var(--dot-shadow);
  animation-name: dotanimation;
  animation-duration: 4s;
  animation-iteration-count: 1;
  z-index: 10;
}

@keyframes dotanimation {
  0% {
    transform: scale(1.5);
    box-shadow: var(--dot-shadow-invisible);
  }
  20% {
    transform: scale(0.9);
    box-shadow: var(--dot-shadow-invisible);
  }
  100% {
    transform: scale(1);
    box-shadow: var(--dot-shadow);
  }
}

.leftColumn {
  flex: 0 0 calc(100% - 60vh);
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.rightColumn {
  flex: 0 0 60vh;
  height: 100%;
  display: flex;
  align-items: center;
}

.conversations {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  list-style: none;
  padding: 0;
  margin: 0;
  text-indent: 0;
  font-weight: 300;
  color: white;
  font-size: 24px;
  overflow: hidden;
  padding: 0 32px;
  padding-right: 80px;
  position: relative;
  max-width: 750px;
  height: 100%;
}

.conversations li {
  margin-top: 40px;
  animation-name: fadein;
  animation-duration: 1s;
  animation-iteration-count: 1;
  padding-right: 100px;
}

.conversations li:nth-child(2n) {
  color: #237cfe;
  text-align: right;
  margin-top: 12px;
  padding-left: 100px;
  padding-right: 0;
  font-weight: 400;
}

.header {
  flex: 0 0 15%;
  display: flex;
  align-items: center;
  padding: 0 32px;
  width: 100%;
}

.header .logo {
  height: 64px;
  margin-top: 12px;
}

.footer {
  flex: 0 0 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1f1f1f;
}

.middle {
  flex: 1 1 100%;
  overflow: hidden;
  display: flex;
}

@keyframes fadein {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.openCommand {
  font-family: Bookerly;
  background-color: white;
  border-radius: 8px;
  padding: 12px 32px;
  font-size: 24px;
}

h1 {
  color: white;
  font-weight: 400;
  margin: 0;
  padding: 0;
  /* margin-left: 24px; */
  font-size: 46px;
  white-space: nowrap;
}
.body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  margin-top: 15%;
}

.body:before {
  background: linear-gradient(
    to bottom,
    rgba(23, 23, 23, 1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 150px;
  width: 100%;
  pointer-events: none;
  z-index: 1;
}
